import React from "react"
import { graphql, Link } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import altStyles from "./WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"
import Blocks from "components/Blocks"

// eslint-disable-next-line
export const PartnerPage = ({ data, html, blogPosts, ...props }) => (
  <Layout {...props} {...data} {...blogPosts}>
    <Container className={altStyles.wrapper}>
      {data.bannerTitle ? (
          <div>
          {data.layout === 'toptitle' ? (
            <>
              <Title className={altStyles.bannertitle}>{data.title}</Title>
              <p className={`${altStyles.title} ${altStyles.titlesmall}`}>
              <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || ""}} />
              </p>
            </>
          ) : (
            <>
              <p className={altStyles.bannertitle}>{data.title}</p>
              <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>
              <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || ""}} />
              </Title>
            </>
          )}
          <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
          <Link className={altStyles.bannerbutton} to={`${data.bannerButtonUrl}`}>{data.bannerButtonText}</Link>
        </div>
      ) : 
      <Title className={altStyles.title}>
        <span>{data.title}</span>
      </Title>
        }
      {data.badge ? (
        <div className={altStyles.image}>
          {data.badge.extension === "svg" ? (
            <img alt={data.bannerImageAltText ? data.bannerImageAltText : data.name} title={data.name} src={data.badge.publicURL} loading="lazy" width="560" height="420" />
          ) : data.badge.childImageSharp ? (
            <Img
              loading="lazy"
              objectFit="contain"
              alt={data.bannerImageAltText ? data.bannerImageAltText : data.name}
              title={data.name}
              fluid={data.badge.childImageSharp.fluid}
            />
          ) : null}
        </div>
      ) : null}
      
      </Container>
      {(data.blocks || []).map((block, i) => {
        const Elem = Blocks[block.type]
        return Elem ? <Elem key={i} {...block} {...blogPosts} /> : null }
      )}

    {data.form && data.portalId ? (
      <div className={altStyles.grayBackground} id="contact-form">
        <Container>
        <div className={altStyles.titleContainer}>
            <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || ""}} /></TitleBlock>
          </div>
          <HubspotForm id={data.form} portalId={data.portalId} />
        </Container>
      </div>
    ) : null}
  </Layout>
)

// eslint-disable-next-line
export const PartnerPageWrapper = (props) => (
  <PartnerPage
    {...props}
    data={props.data.markdownRemark.frontmatter}
    html={props.data.markdownRemark.html}
    blogPosts={props.data.blogPosts}
  />
)

export default PartnerPageWrapper

export const pageQuery = graphql`
  query PartnerPage($id: String, $categories: [String]) {
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: {templateKey: { eq: "blog" }, tags: {in: $categories } } 
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            description
            date(formatString: "MMM Do, YYYY")
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        bannerTitle
        bannerButtonText
        bannerButtonUrl
        formTitle
        form
        portalId
        layout
        tags
        bannerImageAltText
        menuImage {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        menuButtonLabel
        badge {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        blocks {
          type
          title
          description
          buttonText
          buttonUrl
          subTitle
          layout
          bgColor
          iconSize
          paddingTop
          paddingBottom
          arrowsBetweenCards
          imageAltText
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          secondImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          bigListList {
            title
            description
          }
          gridWithImageList {
            title
            description
          }
          imageAndListList
          gridWithIconsList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          bigListWithBackgroundsList {
            title
            description
          }
          gridWithImageAndList {
            title
            description
          }
          cards {
            title
            text
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          gridWithLeftedImage {
            title
            text
          }
          gradientedGridList {
            title
            text
          }
          twoCollGridList {
            title
            text
          }
          iconsWithBackgroundsList {
            title
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          listWithImageAndDescription {
            title
            description
          }
          gridWithIconsAndImageList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagesGrid {
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          partners {
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithImage {
            title
            subtitle
            text
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          textWithCards {
            title
            description
            text
            buttonText
            buttonUrl
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          iconsGridList {
            title
            subtitle
            description
            imageAltText
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
